/*eslint-disable */
import {
  ApolloLink,
  Observable,
  Operation,
  NextLink,
  FetchResult,
} from '@apollo/client';

import Pusher from 'pusher-js';

type RequestResult = Observable<
  FetchResult<{ [key: string]: any }, Record<string, any>, Record<string, any>>
>;

type ObserverOrNext =
  | Function
  | { next: Function; error: Function; complete: Function };

class PusherLink extends ApolloLink {
  pusher: Pusher;

  constructor(options: { pusher: Pusher }) {
    super();
    // Retain a handle to the Pusher client
    this.pusher = options.pusher;
  }

  request(operation: Operation, forward: NextLink): RequestResult {
    const subscribeObservable = new Observable((_observer) => {
      //
    }) as RequestResult;

    // Capture the super method
    const prevSubscribe = subscribeObservable.subscribe.bind(
      subscribeObservable
    );

    // Override subscribe to return an `unsubscribe` object, see
    // https://github.com/apollographql/subscriptions-transport-ws/blob/master/src/client.ts#L182-L212

    //FIXME: does this works correctly after the migration?
    // @ts-ignore
    subscribeObservable.subscribe = (
      observerOrNext: any,
      onError: (error: any) => void,
      onComplete: () => void
    ) => {
      // Call super
      prevSubscribe(observerOrNext, onError, onComplete);

      const observer = getObserver(observerOrNext, onError, onComplete);
      let subscriptionChannel: string;

      forward(operation).subscribe({
        next: (data) => {
          // If the operation has the subscription channel, it's a subscription
          subscriptionChannel = this.getChannelFromResponse(data, operation);

          // No subscription found in the response, pipe data through
          if (!subscriptionChannel) {
            observer.next(data);
            observer.complete();

            return;
          }

          this.subscribeToChannel(subscriptionChannel, observer);
        },
      });

      // Return an object that will unsubscribe_if the query was a subscription
      return {
        closed: false,
        unsubscribe: () => {
          subscriptionChannel &&
            this.unsubscribeFromChannel(subscriptionChannel);
        },
      };
    };

    return subscribeObservable;
  }

  subscribeToChannel(
    subscriptionChannel: string,
    observer: { next: Function; error: Function; complete: Function }
  ) {
    // Set up the pusher subscription for updates from the server
    this.pusher
      .subscribe(subscriptionChannel)
      .bind(
        'lighthouse-subscription',
        (payload: { more: any; result: any }) => {
          if (!payload.more) {
            // This is the end, the server says to unsubscribe
            this.unsubscribeFromChannel(subscriptionChannel);
            observer.complete();
          }

          const result = payload.result;

          if (result) {
            // Send the new response to listeners
            observer.next(result);
          }
        }
      );
  }

  unsubscribeFromChannel(subscriptionChannel: string) {
    this.pusher.unsubscribe(subscriptionChannel);
  }

  getChannelFromResponse(response: FetchResult, operation: Operation) {
    return !!response.extensions &&
      !!response.extensions.lighthouse_subscriptions &&
      !!response.extensions.lighthouse_subscriptions.channels
      ? response.extensions.lighthouse_subscriptions.channels[
          operation.operationName
        ]
      : null;
  }
}

// Turn `subscribe` arguments into an observer-like thing, see getObserver
// https://github.com/apollographql/subscriptions-transport-ws/blob/master/src/client.ts#L329-L343
function getObserver(
  observerOrNext: ObserverOrNext,
  onError: Function,
  onComplete: Function
) {
  if (typeof observerOrNext === 'function') {
    // Duck-type an observer
    return {
      next: (v: object) => observerOrNext(v),
      error: (e: object) => onError && onError(e),
      complete: () => onComplete && onComplete(),
    };
  } else {
    // Make an object that calls to the given object, with safety checks
    return {
      next: (v: object) => observerOrNext.next && observerOrNext.next(v),
      error: (e: object) => observerOrNext.error && observerOrNext.error(e),
      complete: () => observerOrNext.complete && observerOrNext.complete(),
    };
  }
}

export default PusherLink;
/*eslint-enable */
