import styled, { css } from 'styled-components';
import { px2vwHorizontal, px2vwVertical } from '../../../resources/responsive';

export const SessionVoteLayout = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
`;

export const SessionPoint = styled.section`
  background-color: #ffffff;
  width: ${(props: { small?: boolean }) =>
    props.small ? px2vwHorizontal(400) : px2vwHorizontal(535)};
  padding: ${px2vwVertical(20)} ${px2vwVertical(40)};
  position: relative;
  display: flex;
  justify-content: center;
`;

export const Voters = styled.section`
  z-index: 100;
  position: relative;
  width: ${(props: { large?: boolean }) =>
    props.large ? px2vwHorizontal(1250) : px2vwHorizontal(1385)};
  background-color: rgb(240, 240, 240);
  min-width: 0;
`;

export const FooterLogo = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: ${px2vwVertical(150)};
  z-index: 50;
`;

export const UserList = styled.div`
  z-index: 100;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  height: 100%;
  flex-wrap: wrap;
  padding: ${px2vwVertical(10)};
`;

export const UserListFlexboxed = styled(UserList)`
  z-index: 100;
  height: auto;
  justify-content: flex-end;
  padding-left: ${px2vwVertical(20)};
`;

export const User = styled.div`
  padding: 0 ${px2vwVertical(8)};
  margin: ${px2vwVertical(9)} ${px2vwVertical(9)};
  border-radius: ${px2vwVertical(8)};
  text-align: center;
  font-size: ${px2vwVertical(21)};
  line-height: ${px2vwVertical(26)};
  background-color: #ffffff;
  height: ${px2vwVertical(85)};
  color: #9b9b9b;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props: userProps) =>
    props.inFavor &&
    css`
      background-color: #38d569;
      color: #ffffff;
    `}
  ${(props: userProps) =>
    props.abstained &&
    css`
      background-color: #9b9b9b;
      color: #ffffff;
    `}
  ${(props: userProps) =>
    props.against &&
    css`
      background-color: #e53952;
      color: #ffffff;
    `}
    ${(props: userProps) =>
    props.didNotVote &&
    css`
      background-color: #e2e2e2;
    `}
  ${(props: userProps) =>
    props.stretchMX &&
    css`
      line-height: ${px2vwVertical(130)};
    `}
`;

export const UserGroupedList = styled.div`
  z-index: 100;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  height: 100%;
  flex-wrap: wrap;
`;

export const UserGrouped = styled.div`
  padding: ${px2vwVertical(1)} ${px2vwVertical(20)};
  margin: ${px2vwVertical(5)} ${px2vwVertical(0)};
  border-radius: ${px2vwVertical(8)};
  text-align: left;
  font-size: ${px2vwVertical(19)};
  line-height: ${px2vwVertical(23)};
  background-color: #ffffff;
  height: ${px2vwVertical(50)};
  color: #9b9b9b;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;

  ${(props: userProps) =>
    props.inFavor &&
    css`
      background-color: rgba(56, 213, 106, 0.1);
      color: #37d067;
    `}
  ${(props: userProps) =>
    props.abstained &&
    css`
      background-color: #9b9b9b;
      color: #ffffff;
    `}
  ${(props: userProps) =>
    props.against &&
    css`
      background-color: rgba(229, 57, 82, 0.1);
      color: #e53952;
    `}
    ${(props: userProps) =>
    props.didNotVote &&
    css`
      background-color: #e2e2e2;
    `}
`;

interface userProps {
  inFavor?: boolean;
  against?: boolean;
  abstained?: boolean;
  didNotVote?: boolean;
  stretchMX?: boolean;
}

export const UserFlexboxed = styled(User)`
  z-index: 100;
  padding: ${px2vwVertical(10)} ${px2vwVertical(8)};
  width: ${px2vwVertical(100)};
  height: ${px2vwVertical(50)};
  margin: ${px2vwVertical(9)} ${px2vwVertical(9)};
  border-radius: ${px2vwVertical(8)};
`;

export const VotePoint = styled.div`
  background-color: #facd5d;
  padding: ${px2vwVertical(8)};
  font-style: normal;
  font-weight: 600;
  font-size: ${px2vwVertical(22)};
  line-height: ${px2vwVertical(27)};
  text-align: center;
  text-transform: uppercase;
  color: #2d2d2d;
`;

export const PointDescription = styled.div`
  padding: ${px2vwVertical(60)} ${px2vwVertical(28)};
`;

export const CenteredInfoText = styled.div`
  position: absolute;
  left: 50%;
  bottom: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 50;

  font-weight: 600;
  font-size: ${px2vwVertical(50)};
  line-height: ${px2vwVertical(60)};
  color: #9b9b9b;
  text-align: center;
`;

export const DescriptionTitle = styled.div`
  margin: ${px2vwVertical(10)} 0;
  font-weight: 600;
  font-size: ${px2vwVertical(20)};
  line-height: ${px2vwVertical(15)};
  color: #9b9b9b;
`;

export const DescriptionText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: ${px2vwVertical(24)};
  line-height: ${px2vwVertical(32)};
  color: #2d2d2d;
`;

export const List = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  padding: ${px2vwVertical(40)} ${px2vwVertical(30)};
  margin-top: ${px2vwVertical(40)};
`;
export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${px2vwVertical(10)};
`;

export const GroupName = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: ${px2vwVertical(28)};
  line-height: ${px2vwVertical(36)};
  text-align: left;
  color: black;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

export const GroupsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: ${px2vwVertical(40)} ${px2vwVertical(30)};
  margin-top: ${px2vwVertical(40)};
`;
export const GroupItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 ${px2vwVertical(10)} ${px2vwVertical(10)} ${px2vwVertical(10)};
  flex: 1 1 0px;
  flex-basis: 0;
  min-width: 0;
  height: 100%;
  max-width: ${px2vwVertical(400)};
`;

interface VoteDotProps {
  inFavor?: boolean;
  against?: boolean;
  abstained?: boolean;
  didNotVote?: boolean;
  absent?: boolean;
}
export const VoteDot = styled.div`
  border-radius: 50%;
  width: ${px2vwVertical(36)};
  height: ${px2vwVertical(36)};
  margin-right: ${px2vwHorizontal(11)};

  ${(props: VoteDotProps) =>
    props.inFavor &&
    css`
      background-color: #38d569;
    `}
  ${(props: VoteDotProps) =>
    props.against &&
    css`
      background-color: #e53952;
    `}
  ${(props: VoteDotProps) =>
    props.abstained &&
    css`
      background-color: #9b9b9b;
    `}
  ${(props: VoteDotProps) =>
    props.didNotVote &&
    css`
      background-color: #e2e2e2;
    `}
    ${(props: VoteDotProps) =>
    props.absent &&
    css`
      border: 1px solid #9b9b9b;
    `}
`;

export const Result = styled.div`
  font-weight: 500;
  font-size: ${px2vwVertical(28)};
  line-height: ${px2vwVertical(34)};
  text-transform: uppercase;
  color: #2d2d2d;

  small {
    margin-left: 5px;
    color: #9b9b9b;
    font-size: smaller;
    font-weight: 600;
  }
`;

interface ResultAlertProps {
  accepted?: boolean;
  declined?: boolean;
}

export const ResultAlert = styled.div`
  width: 100%;
  height: ${px2vwVertical(91)};
  display: flex;
  border-top-right-radius: ${px2vwVertical(9)};
  border-bottom-right-radius: ${px2vwVertical(9)};
  border-top-left-radius: ${px2vwVertical(9)};
  border-bottom-left-radius: ${px2vwVertical(9)};

  ${(props: ResultAlertProps) =>
    props.accepted &&
    css`
      border: ${px2vwVertical(4)} solid #26bc55;
    `}

  ${(props: ResultAlertProps) =>
    props.declined &&
    css`
      border: ${px2vwVertical(4)} solid #e53952;
    `}

  &:before {
    color: white;
    display: inline-block;
    font-size: ${px2vwVertical(60)};
    text-align: center;
    width: ${px2vwVertical(110)};
    height: ${px2vwVertical(93)};
    top: ${px2vwVertical(-2)};
    left: ${px2vwVertical(-1)};
    position: relative;
    line-height: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: red;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    ${(props: ResultAlertProps) =>
      props.accepted &&
      css`
        content: '\f00c';
        background-color: #26bc55;
        border-bottom: ${px2vwVertical(2)} solid #26bc55;
      `}
    ${(props: ResultAlertProps) =>
      props.declined &&
      css`
        content: '\f00d';
        background-color: #e53952;
        border-bottom: ${px2vwVertical(2)} solid #e53952;
      `};
  }

  span {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${px2vwVertical(28)};
    line-height: ${px2vwVertical(34)};
  }

  ${(props: ResultAlertProps) =>
    props.accepted &&
    css`
      background-color: rgba(56, 213, 106, 0.1);
      color: #37d067;
    `}

  ${(props: ResultAlertProps) =>
    props.declined &&
    css`
      background-color: rgba(229, 57, 82, 0.1);
      color: #e53952;
    `}
`;
export const GroupInterimResult = styled.div`
  margin: ${px2vwVertical(15)} 0;
  width: 100%;
  height: ${px2vwVertical(50)};
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: ${px2vwVertical(18)};
    line-height: ${px2vwVertical(22)};
    color: 'black';
  }
`;

export const GroupResult = styled.div`
  margin: ${px2vwVertical(15)} 0;
  width: 100%;
  height: ${px2vwVertical(50)};
  display: flex;
  border-top-right-radius: ${px2vwVertical(9)};
  border-bottom-right-radius: ${px2vwVertical(9)};
  border-top-left-radius: ${px2vwVertical(9)};
  border-bottom-left-radius: ${px2vwVertical(9)};

  span {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${px2vwVertical(28)};
    line-height: ${px2vwVertical(34)};
    text-transform: uppercase;

    &:before {
      color: white;
      display: inline-block;
      font-size: ${px2vwVertical(28)};
      text-align: center;
      line-height: ${px2vwVertical(34)};

      margin-right: ${px2vwVertical(15)};

      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      ${(props: ResultAlertProps) =>
        props.accepted &&
        css`
          content: '\f00c';
        `}
      ${(props: ResultAlertProps) =>
        props.declined &&
        css`
          content: '\f00d';
        `};
    }
  }

  ${(props: ResultAlertProps) =>
    props.accepted &&
    css`
      // background-color: rgba(56, 213, 106, 0.1);
      background-color: #37d067;
      color: white;
    `}

  ${(props: ResultAlertProps) =>
    props.declined &&
    css`
      // background-color: rgba(229, 57, 82, 0.1);
      background-color: #e53952;
      color: white;
    `}
`;
