import React, { ReactElement } from 'react';
import { SessionPoint, SessionPointVoting } from '../../generated/graphql';
import SessionVote from '../../Layouts/SessionVote';

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  refetch: Function;
  votesUsers: [];
  activeSessionPoint: SessionPoint | undefined;
  activeSessionPointVoting: SessionPointVoting;
}

const OngoingVotingView = (props: Props): ReactElement => {
  const {
    votesUsers,
    activeSessionPoint,
    activeSessionPointVoting,
    refetch,
  } = props;

  // console.log('rerendering OngoingVotingView?');

  return (
    <SessionVote
      refetch={refetch}
      users={votesUsers}
      activeSessionPoint={activeSessionPoint}
      activeSessionPointVoting={activeSessionPointVoting}
    />
  );
};

export default OngoingVotingView;
