import React from 'react';
import styled from 'styled-components';

interface Props {
  speed: number;
}

export default class ScrollText extends React.Component<Props> {
  textRef: any;
  containerRef: any;
  state = {
    clientHeight: 0,
    scrollHeight: 0,
    duration: 0,
  };

  delay = 5;

  handleDurationUpdate = () => {
    if (
      this.textRef &&
      this.containerRef &&
      (this.state.clientHeight !== this.containerRef.clientHeight ||
        this.state.scrollHeight !== this.textRef.scrollHeight)
    ) {
      this.setState({
        clientHeight: this.containerRef.clientHeight, //this.textRef.clientHeight,
        scrollHeight: this.textRef.scrollHeight,
        duration:
          (this.textRef.scrollHeight - this.containerRef.clientHeight + 100) /
          this.props.speed,
      });
    }
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      clientHeight: 0,
      scrollHeight: 0,
      duration: 5,
    };
  }

  componentDidMount() {
    this.handleDurationUpdate();
    window.addEventListener('resize', this.handleDurationUpdate);
  }

  componentDidUpdate() {
    this.handleDurationUpdate();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleDurationUpdate);
  }

  render() {
    const { clientHeight, scrollHeight, duration } = this.state;

    const Container = styled.div`
      // overflow: hidden;
      // word-break: keep-all;
      // white-space: nowrap;
      width: 100%;
      height: 100%;
    `;

    // console.log({
    //   clientHeight,
    //   scrollHeight,
    //   duration,
    //   containerClientHeight:
    //     this.containerRef && this.containerRef.clientHeight,
    //   containerScrollHeight:
    //     this.containerRef && this.containerRef.scrollHeight,
    // });

    const ScrollText = styled.div`
      animation: ${scrollHeight > clientHeight
        ? `scroll ${duration * 2}s ${this.delay}s linear infinite`
        : 'none'};
      animation-fill-mode: forwards;

      @keyframes scroll {
        0% {
          transform: translateY(${0}px);
        }
        50% {
          transform: translateY(-${scrollHeight - clientHeight + 100}px);
        }
        100% {
          transform: translateY(-${scrollHeight - clientHeight + 100}px);
        }
      }
    `;

    // 50% ${(100 * (duration - this.delay)) / duration}% {

    return (
      <Container
        {...this.props}
        ref={(e) => {
          this.containerRef = e;
        }}
      >
        <ScrollText
          ref={(e) => {
            this.textRef = e;
          }}
        >
          {this.props.children}
        </ScrollText>
      </Container>
    );
  }
}
