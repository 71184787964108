import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import logoWhite from '../../resources/images/smartvote-logo-white.svg';
import logoDark from '../../resources/images/smartvote-logo-dark.svg';
import { px2vwHorizontal } from '../../resources/responsive';

interface LogoProps {
  dark?: boolean;
  size: string;
  withOpacity?: boolean;
}

function prop<T, K extends keyof T>(obj: T, key: K) {
  return obj[key];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getViewportSizes = (initial: number, size: any) => {
  const defaultSize = initial;
  const sizes = {
    default: px2vwHorizontal(defaultSize),
    md: px2vwHorizontal(defaultSize * 1.25),
    lg: px2vwHorizontal(defaultSize * 1.5),
    xl: px2vwHorizontal(defaultSize * 1.75),
    xxl: px2vwHorizontal(defaultSize * 2.25),
  };
  return prop(sizes, size) ?? prop(sizes, 'default');
};

/* eslint-disable */
const StyledLogo = styled.div`
  width: ${(props: LogoProps) => getViewportSizes(133.5, props.size)};
  height: ${(props: LogoProps) => getViewportSizes(30, props.size)};
  opacity: ${(props: LogoProps) => (props.withOpacity ? 0.2 : 1)};
  background-image: ${(props: LogoProps) =>
    props.dark ? `url(${logoDark})` : `url(${logoWhite})`};
  background-repeat: no-repeat;
  background-size: cover;
`;
/* eslint-enable */

interface Props {
  dark?: boolean;
  size?: string;
  withOpacity?: boolean;
}

export const Logo: FunctionComponent<Props> = (props: Props): ReactElement => {
  return (
    <StyledLogo
      data-testid="logo"
      {...props}
      size={props.size ?? 'default'}
      withOpacity={props.withOpacity}
    />
  );
};

Logo.defaultProps = {
  dark: false,
};

export default Logo;
