import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import { px2vwVertical, px2vwHorizontal } from '../../../resources/responsive';

interface Props {
  place: string;
  title: string;
}

const SectionHeader = styled.div`
  h2 {
    font-weight: bold;
    font-size: ${px2vwVertical(48)};
    line-height: ${px2vwVertical(108)};
    letter-spacing: ${px2vwVertical(-1)};
    color: #2d2d2d;
  }

  p {
    font-weight: 600;
    font-size: ${px2vwVertical(56)};
    line-height: ${px2vwVertical(68)};
    letter-spacing: ${px2vwVertical(-1)};
    color: #ffffff;
    margin-top: ${px2vwHorizontal(80)};
  }
`;

const SectionTitle = styled.h1`
  font-weight: bold;
  font-size: ${px2vwVertical(88)};
  line-height: ${px2vwVertical(108)};
  letter-spacing: ${px2vwVertical(-1)};
  color: #2d2d2d;
  margin-top: ${px2vwHorizontal(17)};
`;

export const Intro: FunctionComponent<Props> = ({
  place,
  title,
}: Props): ReactElement => {
  return (
    <section>
      <SectionHeader>
        <h2 data-testid="topTitle">Vitajte, práve prebieha</h2>
        <p data-testid="place">{place}</p>
      </SectionHeader>
      <SectionTitle data-testid="title">{title}</SectionTitle>
    </section>
  );
};

export default Intro;
