import React, { ReactElement } from 'react';
import {
  SessionPoint,
  Discussion as DiscussionType,
} from '../../generated/graphql';
import Discussion from '../../Layouts/Discussion';

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  refetch: Function;
  activeSessionPoint: SessionPoint | undefined;
  activeDiscussion: DiscussionType;
}

const DiscussionView = (props: Props): ReactElement => {
  const { activeSessionPoint, activeDiscussion, refetch } = props;

  return (
    <Discussion
      refetch={refetch}
      activeSessionPoint={activeSessionPoint}
      activeDiscussion={activeDiscussion}
    />
  );
};

export default DiscussionView;
