import React, { FunctionComponent, ReactElement } from 'react';

import { Row, Col } from 'react-flexbox-grid';
import {
  User,
  UserFlexboxed,
  UserList,
  UserListFlexboxed,
} from './../styled/index';

interface Props {
  users: Array<any>;
  totalColumns: number;
}

export const ResponsiveUserList: FunctionComponent<Props> = (
  props: Props
): ReactElement => {
  const { users, totalColumns } = props;

  const userListColumnated = (
    <UserList>
      <Row end="sm" style={{ width: '100%', zIndex: 100 }}>
        {users &&
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          users.map((user, index): any => {
            return (
              <Col md={totalColumns} key={index + '-' + user.vote}>
                <User
                  abstained={user.vote === 'abstained'}
                  inFavor={user.vote === 'inFavor'}
                  against={user.vote === 'against'}
                  didNotVote={user.vote === 'didNotVote'}
                  stretchMX={users.length <= 9}
                >
                  {user.full_name}
                </User>
              </Col>
            );
          })}
      </Row>
    </UserList>
  );

  const userListFlexboxed = (
    <UserListFlexboxed>
      {users &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        users.map((user, index): any => {
          return (
            <UserFlexboxed
              abstained={user.vote === 'abstained'}
              inFavor={user.vote === 'inFavor'}
              against={user.vote === 'against'}
              key={index + '-' + user.vote}
            >
              {user.full_name}
            </UserFlexboxed>
          );
        })}
    </UserListFlexboxed>
  );

  const responsiveUserList =
    totalColumns === 1 ? userListFlexboxed : userListColumnated;

  return responsiveUserList;
};

export default ResponsiveUserList;
