import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import Countdown from 'react-countdown';
import Logo from '../../Components/Logo';

import {
  GroupedVotesSummary,
  SessionPoint as SessionPointType,
  SessionPointVoting,
  VotingResult,
} from '../../generated/graphql';
import { WaitForResults } from '../../Pages/components';
import ClampLines from 'react-clamp-lines';

import { px2vwVertical } from '../../resources/responsive';

import {
  DescriptionText,
  DescriptionTitle,
  FooterLogo,
  List,
  ListItem,
  PointDescription,
  SessionPoint,
  SessionVoteLayout,
  ResultAlert,
  VoteDot,
  VotePoint,
  Voters,
  Result,
  CenteredInfoText,
} from './styled/index';

import { ResponsiveUserList, ResponsiveGroupedUserList } from './components/';
interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  refetch: Function;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  users: Array<any>;
  activeSessionPoint: SessionPointType | undefined;
  activeSessionPointVoting: SessionPointVoting;
}

export const SessionVote: FunctionComponent<Props> = (
  props: Props
): ReactElement => {
  const {
    refetch,
    users,
    activeSessionPoint,
    activeSessionPointVoting,
  } = props;

  const [votingTimerIsOver, setVotingTimerIsOver] = useState(false);

  const isGroupVoting =
    activeSessionPointVoting &&
    activeSessionPointVoting.votesSummary &&
    activeSessionPointVoting.votesSummary.groups &&
    activeSessionPointVoting.votesSummary.groups.length > 0;

  useEffect(() => {
    let interval: number | undefined = undefined;

    if (activeSessionPointVoting.vote_state === 'FINISHED_STATE') {
      setVotingTimerIsOver(true);
    } else {
      interval = setInterval(() => {
        refetch();
      }, 5000);
    }

    return () => {
      if (interval !== undefined) {
        clearInterval(interval);
      }
    };
    // eslint-disable-next-line
  }, []);

  const getTotalColumns = (votersLength: number): number => {
    if (votersLength > 1 && votersLength <= 8) {
      return 6;
    } else if (votersLength > 8 && votersLength <= 29) {
      return 4;
    } else if (votersLength > 29 && votersLength <= 38) {
      return 3;
    } else if (votersLength > 38 && votersLength <= 57) {
      return 2;
    }

    return 1;
  };

  const totalColumns = getTotalColumns(users.length);

  return (
    <SessionVoteLayout>
      <SessionPoint small={totalColumns === 1}>
        <section className="votingPanel">
          <VotePoint>
            <span className="opacity-2">
              Bod č. {activeSessionPoint?.order_path}
            </span>
          </VotePoint>

          <VotePoint style={{ marginTop: '1.5vw' }}>
            Hlasovanie č.{activeSessionPointVoting.order_column}
          </VotePoint>

          <PointDescription>
            <DescriptionTitle>Znenie</DescriptionTitle>
            <DescriptionText>
              <ClampLines
                text={activeSessionPointVoting.text}
                id="really-unique-id"
                lines={3}
                ellipsis="..."
                buttons={false}
                innerElement="p"
              />
            </DescriptionText>
          </PointDescription>

          {!votingTimerIsOver &&
            activeSessionPointVoting &&
            activeSessionPointVoting.voting_timer_value && (
              <div style={{ marginTop: px2vwVertical(45) }}>
                <Countdown
                  key="timer-voting"
                  date={
                    Date.now() +
                    (activeSessionPointVoting.voting_timer_value - 2) * 1000
                  }
                  renderer={({ hours, minutes, seconds, completed }) => (
                    <WaitForResults
                      onComplete={() => {
                        setVotingTimerIsOver(completed);
                      }}
                      hours={hours}
                      minutes={minutes}
                      seconds={seconds}
                      completed={completed}
                      voting={activeSessionPointVoting}
                    />
                  )}
                />
              </div>
            )}

          {votingTimerIsOver && (
            <div>
              {!activeSessionPointVoting.result && (
                <PointDescription>
                  <DescriptionText>Kalkulujem výsledky...</DescriptionText>
                </PointDescription>
              )}
              {activeSessionPointVoting.result && (
                <>
                  <ResultAlert
                    declined={
                      activeSessionPointVoting.result ===
                        VotingResult.Declined ||
                      activeSessionPointVoting.result === VotingResult.Repeated
                    }
                    accepted={
                      activeSessionPointVoting.result === VotingResult.Accepted
                    }
                  >
                    <span>
                      {activeSessionPointVoting.result ===
                        VotingResult.Accepted && 'Prijaté'}
                      {activeSessionPointVoting.result ===
                        VotingResult.Declined && 'Neprijaté'}
                      {activeSessionPointVoting.result ===
                        VotingResult.Repeated && 'Zrušené (zopakované)'}
                    </span>
                  </ResultAlert>

                  <List>
                    <ListItem>
                      <div
                        style={{
                          paddingBottom: px2vwVertical(40),
                          width: '100%',
                        }}
                      >
                        <p
                          style={{
                            fontStyle: 'normal',
                            fontWeight: 500,
                            fontSize: px2vwVertical(24),
                            lineHeight: px2vwVertical(32),
                            color: '#2d2d2d',
                            // display: 'flex',
                            // justifyContent: 'space-between',
                          }}
                        >
                          Kvórum:{' '}
                          <strong style={{ fontWeight: 'bold' }}>
                            {activeSessionPointVoting.quorum_type_label}
                          </strong>
                        </p>

                        <p
                          style={{
                            fontStyle: 'normal',
                            fontWeight: 500,
                            fontSize: px2vwVertical(24),
                            lineHeight: px2vwVertical(32),
                            color: '#2d2d2d',
                            // display: 'flex',
                            // justifyContent: 'space-between',
                          }}
                        >
                          Prezentovaní:{' '}
                          <strong style={{ fontWeight: 'bold' }}>
                            {
                              activeSessionPointVoting.checkinSummary.checkedIn
                                .count
                            }
                            /
                            {
                              activeSessionPointVoting.checkinSummary.summary
                                .count
                            }
                          </strong>
                        </p>

                        <p
                          style={{
                            fontStyle: 'normal',
                            fontWeight: 500,
                            fontSize: px2vwVertical(24),
                            lineHeight: px2vwVertical(32),
                            color: '#2d2d2d',
                            // display: 'flex',
                            // justifyContent: 'space-between',
                          }}
                        >
                          Hlasovali:{' '}
                          <strong style={{ fontWeight: 'bold' }}>
                            {
                              activeSessionPointVoting.votesSummary.summary
                                .didVoteCount
                            }
                            /
                            {
                              activeSessionPointVoting.votesSummary.summary
                                .quorumTotalCount
                            }
                          </strong>
                        </p>
                      </div>
                    </ListItem>
                    <ListItem>
                      <VoteDot inFavor />
                      <Result>
                        Za:{' '}
                        <span className="bold">
                          {activeSessionPointVoting.votesSummary.inFavor.count}
                          {!!activeSessionPointVoting.votesSummary.inFavor
                            .showPercentage && (
                            <small>
                              (
                              {
                                activeSessionPointVoting.votesSummary.inFavor
                                  .percentage
                              }
                              %)
                            </small>
                          )}
                        </span>
                      </Result>
                    </ListItem>
                    <ListItem>
                      <VoteDot against />
                      <Result>
                        Proti:{' '}
                        <span className="bold">
                          {activeSessionPointVoting.votesSummary.against.count}
                          {!!activeSessionPointVoting.votesSummary.against
                            .showPercentage && (
                            <small>
                              (
                              {
                                activeSessionPointVoting.votesSummary.against
                                  .percentage
                              }
                              %)
                            </small>
                          )}
                        </span>
                      </Result>
                    </ListItem>
                    <ListItem>
                      <VoteDot abstained />
                      <Result>
                        Zdržalo sa:{' '}
                        <span className="bold">
                          {
                            activeSessionPointVoting.votesSummary.abstained
                              .count
                          }
                          {!!activeSessionPointVoting.votesSummary.abstained
                            .showPercentage && (
                            <small>
                              (
                              {
                                activeSessionPointVoting.votesSummary.abstained
                                  .percentage
                              }
                              %)
                            </small>
                          )}
                        </span>
                      </Result>
                    </ListItem>
                    <ListItem>
                      <VoteDot didNotVote />
                      <Result>
                        Nehlasovali:{' '}
                        <span className="bold">
                          {
                            activeSessionPointVoting.votesSummary.didNotVote
                              .count
                          }
                          {!!activeSessionPointVoting.votesSummary.didNotVote
                            .showPercentage && (
                            <small>
                              (
                              {
                                activeSessionPointVoting.votesSummary.didNotVote
                                  .percentage
                              }
                              %)
                            </small>
                          )}
                        </span>
                      </Result>
                    </ListItem>
                    <ListItem>
                      <VoteDot absent />
                      <Result>
                        Neprítomní:{' '}
                        <span className="bold">
                          {activeSessionPointVoting.votesSummary.absent.count}
                          {!!activeSessionPointVoting.votesSummary.absent
                            .showPercentage && (
                            <>
                              (
                              {
                                activeSessionPointVoting.votesSummary.absent
                                  .percentage
                              }
                              %)
                            </>
                          )}
                        </span>
                      </Result>
                    </ListItem>
                  </List>
                </>
              )}
            </div>
          )}
        </section>
      </SessionPoint>
      <Voters large={totalColumns === 1}>
        {!activeSessionPointVoting.has_anonymized_results && !isGroupVoting && (
          <ResponsiveUserList totalColumns={totalColumns} users={users} />
        )}

        {!activeSessionPointVoting.has_anonymized_results && isGroupVoting && (
          <ResponsiveGroupedUserList
            groups={
              activeSessionPointVoting.votesSummary
                .groups as GroupedVotesSummary[]
            }
          />
        )}

        {activeSessionPointVoting.has_anonymized_results && (
          <CenteredInfoText>Prebieha neverejné hlasovanie</CenteredInfoText>
        )}

        <FooterLogo>
          <Logo dark size="xxl" withOpacity />
        </FooterLogo>
      </Voters>
    </SessionVoteLayout>
  );
};

export default SessionVote;
