import React, { FunctionComponent, ReactElement } from 'react';
import QRCodeReact from 'qrcode.react';
import styled from 'styled-components';
import { px2vwVertical } from '../../resources/responsive';

const ResponsiveSvgWrapper = styled.div`
  & > svg {
    path:first-child {
      fill: rgba(255, 255, 225, 0);
    }
    display: block;
    height: auto;
    width: ${(props: { codeSM: boolean }) =>
      props.codeSM ? `${px2vwVertical(250)}` : `${px2vwVertical(526)}`};
  }
`;

interface ComponentProps {
  value: string;
  small?: boolean;
}

export const QRCode: FunctionComponent<ComponentProps> = ({
  value,
  small,
}: ComponentProps): ReactElement => {
  return (
    <ResponsiveSvgWrapper codeSM={!!small}>
      <QRCodeReact
        data-testid="qr"
        renderAs="svg"
        value={value}
        includeMargin={true}
        level={'H'}
      />
    </ResponsiveSvgWrapper>
  );
};

export default QRCode;
