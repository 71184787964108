import styled from 'styled-components';
import { px2vwHorizontal, px2vwVertical } from '../../../resources/responsive';

export const SessionPointLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Section = styled.section`
  height: 100%;
  padding-left: calc(4.375rem + 3vw);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  top: ${px2vwHorizontal(-60)};
`;

export const SectionTitle = styled.h1`
  max-width: 70%;
  font-weight: bold;
  font-size: ${px2vwVertical(48)};
  line-height: ${px2vwVertical(59)};
  letter-spacing: ${px2vwVertical(1)};
  color: #2d2d2d;
`;

export const SectionDescription = styled.div`
  margin-top: calc(50px + 2vh);
  // margin-bottom: calc(50px + 2vh);
  max-width: 90%;
  font-weight: 500;
  font-size: ${px2vwVertical(36)};
  line-height: ${px2vwVertical(50)};
  letter-spacing: ${px2vwVertical(-1)};
  color: #2d2d2d;
  white-space: pre-wrap;
  overflow: hidden;
`;

export const WaitForVoting = styled.p`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  min-height: ${px2vwVertical(100)};
  background: white;
  padding: 0 30px 30px 30px;

  font-weight: 500;
  font-size: ${px2vwVertical(36)};
  line-height: ${px2vwVertical(50)};
  letter-spacing: ${px2vwVertical(-1)};
  color: #cac9c9;

  &:before {
    background-image: -webkit-linear-gradient(
      -90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 1) 100%
    );
    background-image: -moz-linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 1) 100%
    );
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 1) 100%
    );
    background-position: 50% 50%;
    -webkit-background-origin: padding-box;
    background-origin: padding-box;
    -webkit-background-clip: border-box;
    background-clip: border-box;
    -webkit-background-size: auto auto;
    background-size: auto auto;
    content: '';
    position: absolute;
    width: 100%;
    top: -100px;
    left: 0;
    right: 0;
    height: 100px;
  }
`;

export const Loading = styled.div`
  display: block;
  position: relative;
  width: 80px;
  height: 60px;
  margin: 0 auto;

  & > div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #cac9c9;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  & > div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  & > div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  & > div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  & > div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`;

export const PointHeader = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  & > div {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

export const PointTitle = styled.div`
  background-color: #facd5d;
  opacity: 0.5;
  font-weight: 600;
  font-size: ${px2vwVertical(30)};
  letter-spacing: ${px2vwVertical(1)};
  text-align: center;
  text-transform: uppercase;
  color: #2d2d2d;
`;

export const VoteTitle = styled.div`
  background-color: rgba(250, 205, 93, 0.7);
  font-weight: 600;
  font-size: ${px2vwVertical(30)};
  letter-spacing: ${px2vwVertical(1)};
  text-align: center;
  text-transform: uppercase;
  color: #2d2d2d;
`;

export const SectionHeaderOverlay = styled.div`
  background-color: #facd5d;
  height: ${px2vwVertical(154)};
`;

export const LogoWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: ${px2vwVertical(40)};
`;
