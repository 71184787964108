import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import QRCode from '../../QRCode';
import { px2vwVertical, px2vwHorizontal } from '../../../resources/responsive';

interface Props {
  qrCodeHash: string;
}

const SectionTitle = styled.div`
  text-align: center;
  justify-content: center;
  font-weight: 600;
  font-size: ${px2vwVertical(48)};
  line-height: ${px2vwVertical(108)};
  text-align: center;
  letter-spacing: ${px2vwVertical(-1.3328)};
  color: #2d2d2d;
  margin-top: ${px2vwHorizontal(80)};
`;

export const QRCodeSection: FunctionComponent<Props> = ({
  qrCodeHash,
}: Props): ReactElement => {
  return (
    <>
      <aside>
        <QRCode value={qrCodeHash} />
        <SectionTitle data-testid="title">Naskenujte QR kód</SectionTitle>
      </aside>
    </>
  );
};

export default QRCodeSection;
