import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import { px2vwVertical, px2vwHorizontal } from '../../resources/responsive';

const Heading = styled.h1`
  font-weight: bold;
  font-size: ${px2vwVertical(88)};
  line-height: ${px2vwVertical(108)};
  text-align: center;
  letter-spacing: ${px2vwHorizontal(-1)};
  color: #2d2d2d;
`;

interface Props {
  title: string;
}

export const SectionHeader: FunctionComponent<Props> = ({
  title,
}: Props): ReactElement => {
  return <Heading data-testid="title">{title}</Heading>;
};

export default SectionHeader;
