import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import { px2vwVertical } from '../../resources/responsive';
import SectionHeader from '../../Components/SectionHeader';
import QRCode from '../../Components/QRCode';

const SplitLayout = styled.div`
  width: 100%;
  display: flex;
`;

const LeftColumn = styled.div`
  background-color: #facd5d;
  padding-left: ${px2vwVertical(108)};
  padding-right: ${px2vwVertical(108)};
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const RightColumn = styled.div`
  background-color: #ffffff;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LogoWrapper = styled.div`
  position: absolute;
  bottom: ${px2vwVertical(81)};
  left: ${px2vwVertical(126)};
`;
interface Props {
  title: string;
  left?: ReactElement;
  QRHashCode?: string;
  right: ReactElement;
}

const LeftWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: ${px2vwVertical(66)};
`;

export const SplitScreen: FunctionComponent<Props> = ({
  title,
  left,
  right,
  QRHashCode,
}: Props): ReactElement => {
  return (
    <SplitLayout>
      <LeftColumn>
        <Content>
          {left && <LeftWrapper data-testid="left">{left}</LeftWrapper>}
          {title && <SectionHeader data-testid="title" title={title} />}
        </Content>
        <LogoWrapper>
          {QRHashCode && <QRCode value={QRHashCode} small />}
        </LogoWrapper>
      </LeftColumn>
      <RightColumn>
        <Content data-testid="right">{right}</Content>
      </RightColumn>
    </SplitLayout>
  );
};

export default SplitScreen;
