import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import { px2vwVertical, px2vwHorizontal } from '../../resources/responsive';
import Logo from '../../Components/Logo';
interface ComponentProps {
  left: ReactElement;
  right: ReactElement;
  dateTimeString?: string;
}

const LayoutWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const Left = styled.section`
  background-color: #facd5d;
  padding-top: ${px2vwHorizontal(36)};
  padding-left: ${px2vwVertical(126)};
  flex-direction: column;
  display: flex;
  width: ${px2vwVertical(1245)};
`;

const Content = styled.div`
  flex: 1;
`;

const Footer = styled.div`
  margin-bottom: ${px2vwVertical(81)};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DateTime = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: ${px2vwVertical(36)};
  line-height: ${px2vwHorizontal(72)};
  color: #ffffff;
  margin-right: ${px2vwHorizontal(30)};
`;

const Right = styled.aside`
  width: ${px2vwVertical(683)};
  background-color: #ffffff;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

export const DefaultLayout: FunctionComponent<ComponentProps> = ({
  left,
  right,
  dateTimeString,
}: ComponentProps): ReactElement => {
  return (
    <LayoutWrapper>
      <Left>
        <Content data-testid="left">{left}</Content>
        <Footer>
          <Logo />
          {dateTimeString && (
            <DateTime data-testid="dateTime">{dateTimeString}</DateTime>
          )}
        </Footer>
      </Left>
      <Right data-testid="right">{right}</Right>
    </LayoutWrapper>
  );
};

export default DefaultLayout;
