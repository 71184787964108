import React, { useMemo, ReactElement } from 'react';
import { differenceInSeconds, parseISO } from 'date-fns';
// import Countdown from 'react-countdown';
import CircleTimer from '../../Components/CircleTimer';
import SplitScreen from '../../Layouts/SplitScreen';
import { SessionPointVoting } from '../../generated/graphql';
// import WaitForVoteTimerRenderer from './WaitForVoteTimerRenderer';

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onComplete: Function;
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
  voting: SessionPointVoting;
}

const WaitForPresenting = (props: Props): ReactElement => {
  const { hours, minutes, seconds, completed, onComplete, voting } = props;

  const WAIT_FOR_PRESENTING_ADITIONAL_TIMEOUT = 3 * 1000;

  // FIXME: quickfix, refactor
  return useMemo(() => {
    if (completed) {
      setTimeout(() => {
        onComplete();
      }, WAIT_FOR_PRESENTING_ADITIONAL_TIMEOUT);
    }

    let durationInSec = 30;

    if (voting && voting.checkin_ends_at && voting.checkin_starts_at) {
      durationInSec = differenceInSeconds(
        parseISO(voting.checkin_ends_at),
        parseISO(voting.checkin_starts_at)
      );
    }

    return (
      <>
        <SplitScreen
          title="Aby ste mohli hlasovať, prezentujte sa, prosím"
          right={
            completed ? (
              <h1>Nahrávam...</h1>
            ) : (
              <CircleTimer
                time={hours * 60 * 60 + minutes * 60 + seconds}
                duration={durationInSec}
              />
            )
          }
        />
      </>
    );
    // eslint-disable-next-line
  }, [completed]);
};

export default WaitForPresenting;
