import React, { FunctionComponent, ReactElement } from 'react';

import { orderBy } from 'lodash-es';

import { GroupedVotesSummary, VotingResult } from '../../../generated/graphql';
import {
  UserGrouped,
  GroupsRow,
  GroupItem,
  GroupName,
  UserGroupedList,
  GroupInterimResult,
  GroupResult,
} from './../styled/index';

interface Props {
  groups?: GroupedVotesSummary[];
}

export const ResponsiveGroupedUserList: FunctionComponent<Props> = (
  props: Props
): ReactElement => {
  const { groups } = props;

  const userListColumnated = (group: GroupedVotesSummary) => {
    const groupArr = Object.entries(group);

    /* eslint-disable */
    const users: any = orderBy(
      groupArr
        .map((val: any) => {
          const value = val[1];
          const key = val[0];

          if (value && value.usersList) {
            return value.usersList.map((user: any) => {
              return {
                full_name: user,
                vote: key,
              };
            });
          }

          return [];
        })
        .filter((user): any => user.length >= 1)
        .flat(),
      ['full_name']
    );

    return (
      <>
        {!group.result && (
          <GroupInterimResult>
            <span>
              {
                users.filter(
                  (user: any) =>
                    user.vote &&
                    user.vote !== 'didNotVote' &&
                    user.vote !== 'absent'
                ).length
              }
              /{users.length}
            </span>
          </GroupInterimResult>
        )}

        {group.result && (
          <GroupResult
            declined={
              group.result === VotingResult.Declined ||
              group.result === VotingResult.Repeated
            }
            accepted={group.result === VotingResult.Accepted}
          >
            <span>
              {group.result === VotingResult.Accepted && 'Za'}
              {group.result === VotingResult.Declined && 'Proti'}
            </span>
          </GroupResult>
        )}

        <UserGroupedList>
          {users &&
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            users.map((user: any, index: number): any => {
              return (
                <div
                  style={{
                    flexBasis: '100%',
                    maxWidth: '100%',
                  }}
                  key={index + '-' + user.vote}
                >
                  <UserGrouped
                    abstained={user.vote === 'abstained'}
                    inFavor={user.vote === 'inFavor'}
                    against={user.vote === 'against'}
                    didNotVote={user.vote === 'didNotVote'}
                  >
                    {user.full_name}
                  </UserGrouped>
                </div>
              );
            })}
        </UserGroupedList>
      </>
    );
    /* eslint-enable */
  };

  return (
    <GroupsRow>
      {!!groups &&
        groups.map((group) => (
          <GroupItem key={group.id}>
            <GroupName>{group.title}</GroupName>
            <div style={{ width: '100%' }}>{userListColumnated(group)}</div>
          </GroupItem>
        ))}
    </GroupsRow>
  );
};

export default ResponsiveGroupedUserList;
