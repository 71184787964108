import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { client } from '../config';
import SessionPoint from '../Layouts/SessionPoint';
import DefaultLayout from '../Layouts/DefaultLayout';
import SplitScreen from '../Layouts/SplitScreen';
import IntroSection from '../Components/Sections/Intro';
import QRCodeSection from '../Components/Sections/QRCode';
import Countdown from 'react-countdown';
import styled from 'styled-components';
import IconCalendar from '../resources/images/smartvote-calendar.svg';
import { px2vwVertical } from '../resources/responsive';
import { orderBy } from 'lodash-es';
import QRCode from '../Components/QRCode';
import {
  useGetSessionByTvHashQuery,
  SessionUpdatedDocument,
  DiscussionStateEnum,
} from '../generated/graphql';

import {
  DiscussionView,
  OngoingVotingView,
  WaitForPresenting,
} from './components';

const StyledCalendarIcon = styled.div`
  width: ${px2vwVertical(177)};
  height: ${px2vwVertical(181)};
  background-image: url(${IconCalendar});
  background-repeat: no-repeat;
  background-size: cover;
`;

const parsedDateTime = (time: Date): string => {
  const dateTime = new Date(time);
  const minutes =
    dateTime.getMinutes() < 10
      ? '0' + dateTime.getMinutes()
      : dateTime.getMinutes();
  return `${dateTime.getDate()}.${
    dateTime.getMonth() + 1
  }.${dateTime.getFullYear()} | ${dateTime.getHours()}:${minutes}`;
};

/*eslint-disable */
function subscribeToSessionUpdate(sessionId: number, cb: Function): void {
  const subscription = client.subscribe({
    variables: { session_id: sessionId },
    query: SessionUpdatedDocument,
  });

  subscription.subscribe({
    next(data) {
      const { sessionUpdated } = data.data;
      cb(sessionUpdated);
    },
    error(error) {
      console.error(error);
    },
  });
}

// FIXME: refactor & fix typescript errors

/*eslint-enable */

const Session: FunctionComponent = () => {
  const { sessionHash } = useParams() as {
    sessionHash: string;
  };

  const { loading, refetch, data } = useGetSessionByTvHashQuery({
    variables: { tv_hash: sessionHash },
    client,
    onCompleted: (sessionData) => {
      if (!sessionData || !sessionData.sessionByTvHash) {
        return;
      }
      subscribeToSessionUpdate(Number(sessionData.sessionByTvHash.id), () => {
        refetch();
      });
    },
  });

  const session = data && data.sessionByTvHash ? data.sessionByTvHash : null;

  useEffect(() => {
    if (session) {
      document.title = `Smartvote | ${session.title}`;
    } else if (loading) {
      document.title = 'Smartvote | Nahrávam zasadnutie';
    } else {
      document.title = 'Smartvote';
    }
  }, [session, loading]);

  if (loading) {
    return (
      <h1 style={{ alignSelf: 'center', textAlign: 'center', width: '100%' }}>
        Nahrávam...
      </h1>
    );
  }

  if (session === null) {
    return (
      <h1 style={{ alignSelf: 'center', textAlign: 'center', width: '100%' }}>
        Neexistujúce zasadnutie
      </h1>
    );
  }

  // const VOTING_TIMER_START = 10 * 1000;

  /* eslint-disable */
  const getActiveSessionPoint = (session: any) => {
    let activeSessionPoint = undefined;

    if (session.activeSessionPoint) {
      activeSessionPoint = session.activeSessionPoint;

      if (activeSessionPoint.activeChildSessionPoint) {
        activeSessionPoint = activeSessionPoint.activeChildSessionPoint;
      }
    }

    return activeSessionPoint;
  };
  /* eslint-enable */

  const activeSessionPoint = getActiveSessionPoint(session);
  const hasActiveSessionPoint = activeSessionPoint !== undefined;
  const activeSessionPointVoting =
    activeSessionPoint && activeSessionPoint.activeVoting
      ? activeSessionPoint.activeVoting
      : undefined;

  const hasOngoingVoting =
    activeSessionPointVoting &&
    activeSessionPointVoting.vote_state === 'VOTING_STATE';

  const hasPresentation =
    activeSessionPointVoting &&
    activeSessionPointVoting.vote_state === 'PRESENTATION_STATE';

  const votingIsCancelled =
    activeSessionPointVoting &&
    activeSessionPointVoting.vote_state === 'CANCELLED_STATE';

  const votingIsFinished =
    activeSessionPointVoting &&
    activeSessionPointVoting.vote_state === 'FINISHED_STATE';

  const calculatingResults =
    activeSessionPointVoting &&
    activeSessionPointVoting.vote_state === 'CALCULATING_RESULTS_STATE';

  let votesSummary =
    activeSessionPointVoting === undefined
      ? []
      : Object.entries(activeSessionPointVoting.votesSummary);
  votesSummary = votesSummary.splice(1, votesSummary.length - 1);

  /* eslint-disable */
  const votesUsers: any = orderBy(
    votesSummary
      .map((val: any) => {
        const value = val[1];
        const key = val[0];

        if (value && value.usersList) {
          return value.usersList.map((user: any) => {
            return {
              full_name: user,
              vote: key,
            };
          });
        }

        return [];
      })
      .filter((user): any => user.length >= 1)
      .flat(),
    ['full_name']
  );
  /* eslint-enable */

  const renderOngoingVotingScreen = votingIsFinished || hasOngoingVoting;

  const renderSessionFinishedScreen =
    session.session_state === 'FINISHED_STATE';
  const renderCancelledVoteScreen = votingIsCancelled;
  const renderDefaultScreen = !hasActiveSessionPoint;
  const renderSessionPointScreen =
    hasActiveSessionPoint &&
    !hasPresentation &&
    !hasOngoingVoting &&
    !votingIsFinished &&
    !calculatingResults;
  const renderPresentationCountdownScreen =
    hasPresentation && activeSessionPointVoting.is_checkin_required;

  const discussion = activeSessionPoint && activeSessionPoint.discussion;
  const renderDiscussionScreen =
    !activeSessionPointVoting &&
    discussion &&
    discussion.state === DiscussionStateEnum.Active;
  // const renderPleaseVoteScreen =
  //   hasOngoingVoting &&
  //   !activeSessionPointVoting &&
  //   activeSessionPointVoting.voting_timer_value !== null;

  if (renderSessionFinishedScreen) {
    return (
      <SplitScreen
        title="Ďakujeme za vašu účasť"
        left={<StyledCalendarIcon />}
        right={
          session?.workspace.logo ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                width="100%"
                src={session?.workspace.logo}
                alt="Logo Samospravy"
              />
            </div>
          ) : (
            <div></div>
          )
        }
      />
    );
  } else if (renderCancelledVoteScreen) {
    return (
      <SplitScreen
        title="Hlasovanie bolo neúspešné, nedostatočný počet prezentovaných účastníkov"
        right={<QRCode value={session.qr_hash} />}
      />
    );
  } else if (renderDiscussionScreen) {
    return (
      <DiscussionView
        refetch={refetch}
        activeSessionPoint={activeSessionPoint}
        activeDiscussion={activeSessionPoint.discussion}
      />
    );
    //   /* Default layout is displayed when no session point nor session voting point is active */
  } else if (renderDefaultScreen) {
    return (
      <DefaultLayout
        left={<IntroSection place={session.place} title={session.title} />}
        right={<QRCodeSection qrCodeHash={session.qr_hash} />}
        dateTimeString={parsedDateTime(session.starts_at)}
      />
    );

    //   /* SessionPoint is only active, when sessionPoint has any active point and no voting is in presentation state*/
  } else if (renderSessionPointScreen) {
    return (
      <SessionPoint
        title={session.title}
        sessionPoint={activeSessionPoint}
        sessionPointVoting={activeSessionPointVoting}
        // description={activeSessionPoint.text}
        // pointNumber={activeSessionPoint.order_path}
        // sessionPointNumber={
        //   activeSessionPointVoting && activeSessionPointVoting.order_column
        // }
        // showSessionVoteNumber={activeSessionPointVoting}
      />
    );

    // Voting has presentation and checking is required
    // We display timer for required presentation
  } else if (renderPresentationCountdownScreen) {
    return (
      <Countdown
        key="timer-1"
        date={
          Date.now() + (activeSessionPointVoting.checkin_timer_value - 2) * 1000
        }
        renderer={({ hours, minutes, seconds, completed }) => (
          <WaitForPresenting
            onComplete={refetch}
            hours={hours}
            minutes={minutes}
            seconds={seconds}
            completed={completed}
            voting={activeSessionPointVoting}
          />
        )}
      />
    );
  }

  // FIXME: zatial pre jednoduchost zruseny stav
  // else if (renderPleaseVoteScreen) {
  //   return (
  //     <Countdown
  //       key="timer-1"
  //       date={Date.now() + 5 * 1000}
  //       renderer={({ hours, minutes, seconds, completed }) => (
  //         <WaitForVoteTimerRenderer
  //           onComplete={refetch}
  //           hours={hours}
  //           minutes={minutes}
  //           seconds={seconds}
  //           completed={completed}
  //           votesUsers={votesUsers}
  //           activeSessionPoint={activeSessionPoint}
  //           activeSessionPointVoting={activeSessionPointVoting}
  //         />
  //       )}
  //     />
  //   );
  // }

  return (
    <>
      {(renderOngoingVotingScreen || calculatingResults) && (
        <OngoingVotingView
          refetch={refetch}
          votesUsers={votesUsers}
          activeSessionPoint={activeSessionPoint}
          activeSessionPointVoting={activeSessionPointVoting}
        />
      )}
    </>
  );
};

export default Session;
