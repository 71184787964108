import React, { useMemo, FC, useEffect } from 'react';
import CircleTimer from '../../Components/CircleTimer';

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onComplete: Function;
  seconds: number;
  duration: number;
  completed: boolean;
}

const WaitForDiscussionContribution: FC<Props> = ({
  onComplete,
  seconds,
  duration,
  completed,
}: Props) => {
  useEffect(() => {
    if (completed) {
      onComplete();
    }
    // eslint-disable-next-line
  }, [completed]);

  return useMemo(() => {
    return completed ? null : (
      <CircleTimer time={seconds} duration={duration} />
    );
    // eslint-disable-next-line
  }, [completed]);
};

export default WaitForDiscussionContribution;
