import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import ClampLines from 'react-clamp-lines';
// @ts-ignore
import useCountDown from 'react-countdown-hook';

import {
  DiscussionNoteType,
  DiscussionParticipant,
  DiscussionParticipantPaginator,
  DiscussionStateEnum,
  Maybe,
  Scalars,
  SessionPoint as SessionPointType,
} from '../../generated/graphql';

import {
  DescriptionText,
  DescriptionTitle,
  DiscussionLayout,
  DiscussionNoteTypeLabel,
  DiscussionPoint,
  FooterLogo,
  NoActivePresenting,
  Participant,
  ParticipantInfo,
  ParticipantName,
  ParticipantOrder,
  Participants,
  ParticipantsList,
  PointDescription,
  SessionPoint,
  ParticipantsHead,
  ParticipantsHeadTitle,
  //ParticipantsHeadNav,
  CountdownTime,
} from './styled';
import { px2vwVertical } from '../../resources/responsive';
import Countdown from 'react-countdown';
import Logo from '../../Components/Logo';
import WaitForDiscussionContribution from '../../Pages/components/WaitForDiscussionContribution';

type DiscussionType = {
  __typename?: 'Discussion';
  id: Scalars['ID'];
  start_at?: Maybe<Scalars['DateTime']>;
  end_at?: Maybe<Scalars['DateTime']>;
  session_id: Scalars['ID'];
  participation_requests_open: Scalars['Boolean'];
  allParticipants?: Maybe<DiscussionParticipantPaginator>;
  activeParticipants?: Maybe<DiscussionParticipantPaginator>;
  state: DiscussionStateEnum;
};

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  refetch: Function;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activeSessionPoint: SessionPointType | undefined;
  activeDiscussion: DiscussionType;
}

export const Discussion: FunctionComponent<Props> = (
  props: Props
): ReactElement => {
  const { refetch, activeSessionPoint, activeDiscussion } = props;

  const [discussTimeIsOver, setDiscussTimeIsOver] = useState(false);

  const participantsTotal =
    activeDiscussion.allParticipants?.paginatorInfo.total || 0;
  const participants =
    participantsTotal && participantsTotal > 32
      ? activeDiscussion.activeParticipants?.data
      : activeDiscussion.allParticipants?.data;

  const activeParticipant = participants?.find(
    (participant: DiscussionParticipant) =>
      participant.state === DiscussionStateEnum.Active
  );

  const activeContributionDurationInSeconds = activeParticipant
    ? (Date.parse(activeParticipant.finished_at) -
        Date.parse(activeParticipant.started_at)) /
      1000
    : undefined;

  const initialTime = 60 * 1000; // initial time in milliseconds, defaults to 60000
  const interval = 1000; // interval to change remaining time amount, defaults to 1000
  const [timeLeft, { start }] = useCountDown(initialTime, interval);

  useEffect(() => {
    let interval: number | undefined = undefined;

    interval = setInterval(() => {
      refetch();
    }, 2000);

    return () => {
      if (interval !== undefined) {
        clearInterval(interval);
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (activeParticipant && activeParticipant.timer_value) {
      start(activeParticipant.timer_value * 1000);
      setDiscussTimeIsOver(false);
    }
    // eslint-disable-next-line
  }, [activeParticipant?.timer_value]);

  const paticipantsList = (
    <ParticipantsList large={participants?.length! >= 25}>
      {participants &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        participants.map((participant, index): any => {
          const user =
            participant.sessionWorkspaceAccount.workspaceAccount.user;
          return (
            <Participant
              key={participant.id}
              active={participant.state === DiscussionStateEnum.Active}
              finished={participant.state === DiscussionStateEnum.Finished}
              upcoming={participant.state === DiscussionStateEnum.Pending}
            >
              <ParticipantOrder
                active={participant.state === DiscussionStateEnum.Active}
                finished={participant.state === DiscussionStateEnum.Finished}
              >
                {participant.order}
              </ParticipantOrder>
              <ParticipantInfo>
                <ParticipantName
                  finished={participant.state === DiscussionStateEnum.Finished}
                >
                  {`${user?.first_name} ${user?.last_name}`}
                </ParticipantName>
                <DiscussionNoteTypeLabel>
                  {participant.note_type === DiscussionNoteType.Factual
                    ? `Faktická poznámka`
                    : `Riadny príspevok`}
                </DiscussionNoteTypeLabel>
              </ParticipantInfo>
            </Participant>
          );
        })}
    </ParticipantsList>
  );

  /* const factualContributions = participants?.filter(
    (contribution) => contribution.note_type === DiscussionNoteType.Factual
  );
  const regularContributions = participants?.filter(
    (contribution) => contribution.note_type === DiscussionNoteType.Regular
  );*/

  const secondsToHms = (d: number) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + ':' : '';
    var mDisplay = m > 0 ? m + ':' : '0:';
    var sDisplay = s < 10 ? '0' + s : s;
    return hDisplay + mDisplay + sDisplay;
  };
  // @ts-ignore
  return (
    <DiscussionLayout>
      <SessionPoint>
        <section className="votingPanel" style={{ width: '100%' }}>
          <DiscussionPoint>
            <span className="opacity-2">
              Bod č. {activeSessionPoint?.order_path}
            </span>
          </DiscussionPoint>

          {activeSessionPoint?.text && (
            <PointDescription>
              <DescriptionTitle>Znenie</DescriptionTitle>
              <DescriptionText>
                <ClampLines
                  text={activeSessionPoint.text}
                  id="really-unique-id"
                  lines={3}
                  ellipsis="..."
                  buttons={false}
                  innerElement="p"
                />
              </DescriptionText>
            </PointDescription>
          )}

          {!discussTimeIsOver && activeParticipant && (
            <div style={{ marginTop: px2vwVertical(45), position: 'relative' }}>
              <CountdownTime>{secondsToHms(timeLeft / 1000)}</CountdownTime>
              <Countdown
                key="timer-voting"
                date={Date.now() + activeParticipant?.timer_value! * 1000}
                renderer={({ minutes, seconds, completed }) => (
                  <div>
                    <WaitForDiscussionContribution
                      onComplete={() => {
                        setDiscussTimeIsOver(completed);
                      }}
                      seconds={minutes * 60 + seconds}
                      duration={activeContributionDurationInSeconds!}
                      completed={completed}
                    />
                  </div>
                )}
              />
            </div>
          )}

          {!activeParticipant && (
            <div style={{ marginTop: px2vwVertical(55) }}>
              <NoActivePresenting>
                Aktuálne neprebieha <br /> žiadny príspevok
              </NoActivePresenting>
            </div>
          )}
        </section>
      </SessionPoint>

      <Participants>
        <ParticipantsHead>
          <ParticipantsHeadTitle>
            Zoznam príspevkov v rozprave ({participantsTotal})
          </ParticipantsHeadTitle>
          {/*<div style={{ display: 'flex' }}>
            <ParticipantsHeadNav>
              <span style={{ fontWeight: 700 }}>
                {regularContributions?.length}
              </span>{' '}
              {regularContributions?.length === 1 && 'riadny príspevok'}
              {regularContributions?.length! > 1 &&
                regularContributions?.length! <= 4 &&
                'riadne príspevky'}
              {regularContributions?.length! > 4 && 'riadnych príspevkov'}
            </ParticipantsHeadNav>
            <ParticipantsHeadNav>
              <span style={{ fontWeight: 700 }}>
                {factualContributions?.length}
              </span>{' '}
              {factualContributions?.length === 1 && 'faktická poznámka'}
              {factualContributions?.length! > 1 &&
                factualContributions?.length! <= 4 &&
                'faktické poznámky'}
              {factualContributions?.length! > 4 && 'faktických poznámok'}
            </ParticipantsHeadNav>
          </div>*/}
        </ParticipantsHead>
        {paticipantsList}
        <FooterLogo>
          <Logo dark size="xxl" withOpacity />
        </FooterLogo>
      </Participants>
    </DiscussionLayout>
  );
};

export default Discussion;
