import React, {
  FunctionComponent,
  ReactElement,
  useRef,
  useEffect,
} from 'react';
import styled from 'styled-components';
import { px2vwHorizontal } from '../../resources/responsive';
import './index.scss';
const StyledTimer = styled.div`
  width: ${px2vwHorizontal(250)};
  height: ${px2vwHorizontal(250)};
  position: relative;
`;

const StyledCircleInner = styled.div`
  position: absolute;
  width: ${px2vwHorizontal(200)};
  height: ${px2vwHorizontal(200)};
  left: ${px2vwHorizontal(25)};
  top: ${px2vwHorizontal(25)};
  border-radius: 50%;
  background-color: #facd5d;
  opacity: 15%;
`;

interface Props {
  time?: number;
  duration?: number;
}

export const CircleTimer: FunctionComponent<Props> = ({
  time,
  duration,
}: Props): ReactElement => {
  const circleTimer = useRef(null);
  const Time = useRef(time);
  const StartTime = useRef(duration);

  useEffect(() => {
    Time.current = time === undefined ? 5 : time;
    StartTime.current = duration === undefined ? Time.current : duration;

    svgPieTimer({
      element: [circleTimer.current],
      progress: Time.current * 1000,
      duration: StartTime.current * 1000,
      loops: 1,
    });
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function draw(element: any, rate: number) {
    let count = element.length;
    let angle = 360 * rate;

    angle %= 360;

    const rad = (angle * Math.PI) / 180,
      x = Math.sin(rad) * 125,
      y = Math.cos(rad) * -125,
      mid = angle > 180 ? 1 : 0,
      shape = 'M 0 0 v -125 A 125 125 1 ' + mid + ' 1 ' + x + ' ' + y + ' z';

    // If array of elements, draw each one
    if (element instanceof Array)
      while (count--) element[count].setAttribute('d', shape);
    else element.setAttribute('d', shape);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function svgPieTimer(settings: any) {
    const element = settings.element,
      duration = settings.duration,
      progress = settings.progress;

    let n = settings.loops;

    // This part might be confusing:
    // If n==0, do infinite loops
    // In other cases where n is set, do n loops
    // If n is not set, do 1 loop
    // Do it this way to prevent mixing n==0 and !n

    n = n === 0 ? 0 : n ? n : 1;

    const end = Date.now() + duration * n,
      totaldur = duration * n;

    // Animate frame by frame
    (function frame() {
      const current = Date.now() - progress,
        remaining = end - current,
        // Now set rotation rate
        // E.g. 50% of first loop returns 1.5
        // E.g. 75% of sixth loop returns 6.75
        // Has to return >0 for SVG to be drawn correctly
        // If you need the current loop, use Math.floor(rate)

        rate = n + 1 - remaining / duration;

      // As requestAnimationFrame will draw whenever capable,
      // the animation might end before it reaches 100%.
      // Let's simulate completeness on the last visual
      // frame of the loop, regardless of actual progress

      if (remaining < 60) {
        // 1.0 might break, set to slightly lower than 1
        // Update: Set to slightly lower than n instead

        draw(element, n - 0.0001);

        // Stop animating when we reach n loops (if n is set)

        if (remaining < totaldur && n) return;
      }

      // To reverse, uncomment this line
      // if (settings.reverse) {
      //   rate = 360 - rate;
      // }

      // Draw
      draw(element, rate);

      // Request next frame

      requestAnimationFrame(frame);
    })();
  }

  return (
    <StyledTimer className="timer">
      <StyledCircleInner />
      <svg viewBox="0 0 250 250">
        <path
          className="loader"
          ref={circleTimer}
          transform="translate(125,125) scale(1)"
        />
      </svg>
    </StyledTimer>
  );
};

export default CircleTimer;
