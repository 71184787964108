import React, { FunctionComponent } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Grid } from 'react-flexbox-grid';
import { Session } from './Pages';

const App: FunctionComponent = () => {
  return (
    <Grid fluid className="page noGutters">
      <Router>
        <Switch>
          <Route path="/session/:sessionHash">
            <Session />
          </Route>
        </Switch>
      </Router>
    </Grid>
  );
};

export default App;
