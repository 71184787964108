import styled, { css } from 'styled-components';
import { px2vwHorizontal, px2vwVertical } from '../../../resources/responsive';

export const DiscussionLayout = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
`;

export const SessionPoint = styled.section`
  background-color: #ffffff;
  width: ${px2vwHorizontal(445)};
  padding: ${px2vwVertical(20)} ${px2vwVertical(40)};
  position: relative;
  display: flex;
  justify-content: center;
`;

export const DiscussionPoint = styled.div`
  background-color: #facd5d;
  padding: ${px2vwVertical(8)};
  font-style: normal;
  font-weight: 600;
  font-size: ${px2vwVertical(22)};
  line-height: ${px2vwVertical(27)};
  text-align: center;
  text-transform: uppercase;
  color: #2d2d2d;
`;

export const PointDescription = styled.div`
  padding: ${px2vwVertical(60)} ${px2vwVertical(28)};
`;

export const DescriptionTitle = styled.div`
  margin: ${px2vwVertical(10)} 0;
  font-weight: 600;
  font-size: ${px2vwVertical(20)};
  line-height: ${px2vwVertical(15)};
  color: #9b9b9b;
`;

export const DescriptionText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: ${px2vwVertical(16)};
  line-height: ${px2vwVertical(20)};
  color: #2d2d2d;
`;

export const NoActivePresenting = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: ${px2vwVertical(32)};
  line-height: ${px2vwVertical(38)};
  color: #2d2d2d;
  align-self: center;
  text-align: center;
  width: 100%;
`;

export const ParticipantsHead = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0 0 20px;
`;

export const ParticipantsHeadTitle = styled.div`
  font-weight: 700;
  font-size: ${px2vwVertical(24)};
`;

export const ParticipantsHeadNav = styled.div`
  margin-right: 20px;
`;

export const Participants = styled.section`
  z-index: 100;
  position: relative;
  width: ${(props: { large?: boolean }) =>
    props.large ? px2vwHorizontal(1250) : px2vwHorizontal(1385)};
  background-color: rgb(240, 240, 240);
`;

export const ParticipantsList = styled.div`
  z-index: 100;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(8, 1fr);
  grid-auto-flow: column;
  height: 90%;
  padding: ${px2vwVertical(10)};

  ${(props: { large: boolean }) =>
    props.large &&
    css`
      grid-template-columns: repeat(4, 1fr);
    `}
`;

export const Participant = styled.div`
  z-index: 100;
  padding: ${px2vwVertical(12)} ${px2vwVertical(12)};
  margin: ${px2vwVertical(9)} ${px2vwVertical(9)};
  display: flex;
  line-height: ${px2vwVertical(26)};
  background-color: #ffffff;
  color: #9b9b9b;
  height: 50px;
  width: auto;

  ${(props: participantProps) =>
    props.active &&
    css`
      background-color: #fee490;
      color: #000000;
    `}

  ${(props: participantProps) =>
    props.upcoming &&
    css`
      background-color: #ffffff;
      color: #000000;
    `}
  
  ${(props: participantProps) =>
    props.finished &&
    css`
      background-color: #ffffff;
      color: #9b9b9b;
    `}
`;

interface participantProps {
  active?: boolean;
  upcoming?: boolean;
  finished?: boolean;
}
export const ParticipantOrder = styled.div`
  width: ${px2vwVertical(40)};
  height: ${px2vwVertical(40)};
  background-color: #5c5c5c;
  color: #ffffff;
  line-height: ${px2vwVertical(40)};
  font-size: ${px2vwVertical(25)};
  font-weight: 700;
  text-align: center;
  border-radius: ${px2vwVertical(8)};
  margin: auto ${px2vwVertical(8)};

  ${(props: participantProps) =>
    props.active &&
    css`
      background-color: #fed03e;
      color: #000000;
    `}

  ${(props: participantProps) =>
    props.finished &&
    css`
      background-color: #f1f1f1;
      color: #d7d7d7;
    `}
`;

export const ParticipantName = styled.div`
  font-size: ${px2vwVertical(18)};
  line-height: ${px2vwVertical(20)};
  font-weight: 700;
  color: #000000;

  ${(props: participantProps) =>
    props.finished &&
    css`
      color: #9b9b9b;
    `}
`;

export const ParticipantInfo = styled.div`
  margin: auto 4px;
`;

export const CountdownTime = styled.div`
  z-index: 9999;
  color: #000000;
  position: absolute;
  font-size: ${px2vwVertical(32)};
  font-weight: 700;
  top: 47%;
  left: 45%;
`;

export const DiscussionNoteTypeLabel = styled.div`
  font-size: ${px2vwVertical(16)};
  line-height: ${px2vwVertical(18)};
`;

export const FooterLogo = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: ${px2vwVertical(150)};
  z-index: 50;
`;
