import PusherLink from '../PusherLink';
import {
  HttpLink,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import Pusher from 'pusher-js';
import Bugsnag from '@bugsnag/js';

const PUSHER_API_KEY = '2775efba3b854e5b8218';
const PUSHER_DEV_API_KEY = '568f381bdd95dd48c614';
const PUSHER_CLUSTER = 'eu';
export const API_LOCATION = 'https://app.smartvote.sk';
export const API_DEV_LOCATION = 'https://dev.smartvote.sk';
const BEARER_TOKEN = 'abcd';
const BUGSNAG_KEY = '9084eaebc2e313350ec1f87386e000d7';

const IS_PRODUCTION = window.location.hostname === 'tv.smartvote.sk';
const API_ENV_LOCATION = IS_PRODUCTION ? API_LOCATION : API_DEV_LOCATION;
const PUSHER_ENV_API_KEY = IS_PRODUCTION ? PUSHER_API_KEY : PUSHER_DEV_API_KEY;

let bugsnagClient: any;

if (process.env.NODE_ENV === 'production') {
  bugsnagClient = Bugsnag.start({
    apiKey: BUGSNAG_KEY,
  });
} else {
  bugsnagClient = {
    notify: () => ({}),
  };
}

export { bugsnagClient };

export const pusher = new Pusher(PUSHER_ENV_API_KEY, {
  cluster: PUSHER_CLUSTER,
  authEndpoint: `${API_ENV_LOCATION}/graphql/subscriptions/auth`,
  auth: {
    headers: {
      authorization: BEARER_TOKEN,
    },
  },
});

const pusherLink = new PusherLink({
  pusher,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      console.log({ locations });
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const link = ApolloLink.from([
  errorLink,
  pusherLink,
  new HttpLink({ uri: `${API_ENV_LOCATION}/graphql` }),
]);

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});
