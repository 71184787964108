import React, { FC, useMemo } from 'react';
import { differenceInSeconds, parseISO } from 'date-fns';

import CircleTimer from '../../Components/CircleTimer';
import { SessionPointVoting } from '../../generated/graphql';

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onComplete: Function;
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
  voting: SessionPointVoting;
}

const WaitForResults: FC<Props> = ({
  onComplete,
  hours,
  minutes,
  seconds,
  completed,
  voting,
}: Props) => {
  // const WAIT_FOR_PRESENTING_ADITIONAL_TIMEOUT = 3 * 1000;
  // FIXME: quickfix, refactor
  return useMemo(() => {
    if (completed) {
      // setTimeout(() => {
      onComplete();
      // }, WAIT_FOR_PRESENTING_ADITIONAL_TIMEOUT);
    }

    let durationInSec = 30;

    if (voting && voting.voting_ends_at && voting.voting_starts_at) {
      durationInSec = differenceInSeconds(
        parseISO(voting.voting_ends_at),
        parseISO(voting.voting_starts_at)
      );
    }

    return completed ? null : (
      <CircleTimer
        time={hours * 60 * 60 + minutes * 60 + seconds}
        duration={durationInSec}
      />
    );
    // eslint-disable-next-line
  }, [completed]);
};

export default WaitForResults;
