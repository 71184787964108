import React, { FunctionComponent, ReactElement } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Logo from '../../Components/Logo';
import ScrollText from '../../Components/ScrollText';
import {
  SessionPoint,
  SessionPointVoting,
  VoteState,
} from '../../generated/graphql';
import {
  SessionPointLayout,
  SectionHeaderOverlay,
  PointHeader,
  PointTitle,
  VoteTitle,
  Section,
  SectionTitle,
  SectionDescription,
  LogoWrapper,
  WaitForVoting,
  Loading,
} from './styled';

interface Props {
  title: string;
  sessionPoint: SessionPoint | undefined;
  sessionPointVoting?: SessionPointVoting;
}

export const DefaultLayout: FunctionComponent<Props> = (
  props: Props
): ReactElement => {
  const { title, sessionPoint, sessionPointVoting } = props;

  const checkinPercentage = sessionPointVoting
    ? (100 / sessionPointVoting.checkinSummary.summary.count) *
      sessionPointVoting.checkinSummary.checkedIn.count
    : 0;

  return (
    <SessionPointLayout>
      <Grid fluid>
        <Row>
          <Col className="noGutters" sm={9}>
            <SectionHeaderOverlay />
          </Col>

          <Col className="noGutters" data-testid="pointHeader" sm={3}>
            <PointHeader>
              <PointTitle data-testid="pointNumber">
                Bod č. {sessionPoint?.order_path}
              </PointTitle>
              {sessionPointVoting && (
                <VoteTitle data-testid="sessionPointNumber">
                  Hlasovanie č. {sessionPointVoting.order_column}
                </VoteTitle>
              )}
            </PointHeader>
          </Col>
        </Row>
      </Grid>
      <Section>
        <SectionTitle data-testid="title">{title}</SectionTitle>
        {/* <div
          style={{
            animation: 'scroll 277.4s linear infinite',
            animationFillMode: 'forwards',
          }}
        > */}
        <SectionDescription data-testid="description">
          <ScrollText speed={10}>
            {sessionPointVoting ? sessionPointVoting.text : sessionPoint?.text}
          </ScrollText>
        </SectionDescription>
        {/* </div> */}
        <LogoWrapper>
          <Logo dark size="xxl" withOpacity />
        </LogoWrapper>
      </Section>
      {sessionPointVoting &&
        sessionPointVoting.vote_state === VoteState.WaitingForVoteState && (
          <WaitForVoting>
            <Loading>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </Loading>
            Počkajte, kým sa spustí hlasovanie
            <br /> Počet prezentovaných:{' '}
            {sessionPointVoting.checkinSummary.checkedIn.count}/
            {sessionPointVoting.checkinSummary.summary.count} (
            {checkinPercentage.toFixed(2)} %)
          </WaitForVoting>
        )}
    </SessionPointLayout>
  );
};

export default DefaultLayout;
